import React from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

const HomeThreeProjects = () => {
   // slick setting
   const settings = {
      autoplay: true,
      autoplaySpeed: 2000,
      height: 100,
      dots: true,
      fade: false,
      arrows: false,
      cssEase: "linear",
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
         {
            breakpoint: 767,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               initialSlide: 2
            }
         },
         {
            breakpoint: 400,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               initialSlide: 1
            }
         },
      ]
   }
   const testimonialData = [
      {
         id: 1,
         title: <h3>Unmatched Quality and Attention to Detail</h3>,
         img: "assets/img/project/project-1.webp",
         p: "Dezthetics delivers top-tier video editing with a focus on creativity and precision, ensuring that every project reflects your unique vision.",
      },
      {
         id: 2,
         title: <h3>Tailored Solutions at Reasonable Prices</h3>,
         img: "assets/img/project/project-2.webp",
         p: "We offer customized video editing services designed specifically for your needs, all at competitive rates",
      },
      {
         id: 3,
         title: <h3>Industry Expertise and Commitment to Excellence</h3>,
         img: "assets/img/project/project-3.webp",
         p: "Our experienced team, having collaborated with global creators and top media agencies in Pakistan, brings a deep commitment to excellence in every frame.",
      },
   ]
   return (
      <>
         <section className="project__area pt-115 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-7">
                     <div className="section-title section__title-3 mb-70">
                        <h2 style={{ textAlign: 'start', fontSize: '42px' }}>Why Dezthetics?</h2>
                        <p style={{ textAlign: 'start', fontSize: '18px' }}>We offer unmatched quality, delivering customized video editing solutions tailored to your unique vision. With our expert craftsmanship and industry experience, we ensure every project meets the highest standards of creativity and precision.</p>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-xl-12">

                     <Slider className='project__slider' {...settings}>

                        {
                           testimonialData.map((testimonial, index) => {
                              return <div key={index} className="project__item grey-bg-19">
                                 <div className="project__content">
                                    <div className="project__no">
                                       <h5>{testimonial.id}</h5>
                                    </div>
                                    <div className='project__content__headline' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: "space-around" }}>
                                       <div>
                                          <p>{testimonial.title}</p>
                                          <div style={{ flexDirection: 'column', justifyContent: 'center' }} className="project__thumb m-img d-flex">
                                             <p style={{ fontSize: "16px", }}>{testimonial.p}</p>
                                          </div>
                                       </div>
                                    </div>
                                    <img style={{ marginBottom: "180px" }} src={testimonial.img} alt="img" />
                                 </div>

                              </div>
                           })
                        }

                     </Slider>

                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeThreeProjects;