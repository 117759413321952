import React from 'react';

const HomeContact = () => {
   return (
      <section id="contact" className="contact__area">
         <div className="container-fluid">
            <div className="contact__wrapper">
               <div className="contact__info">
                  <h3>Get in touch</h3>
                  <ul>
                     <li>
                        <h4>Office:</h4>
                        <p>Karachi, Pakistan</p>
                     </li>
                     <li>
                        <h4>Call us</h4>
                        <p><a href="tel:+923181326463">03181326463</a></p>
                        <p><a href="tel:+92308002420">0308002420</a></p>
                     </li>
                     <li>
                        <h4>Email Address</h4>
                        <p><a href="mailto:admin@dezthetics.com">admin@dezthetics.com</a></p>
                     </li>
                  </ul>
               </div>
               <div className="contact__form">
                  <form method="POST" action="https://dezthetics.com/src/pages/Home/HomeContact/mail.php">
                     <input type="text" name="name" placeholder="Your Name" required />
                     <input type="email" name="email" placeholder="Your Email" required />
                     <input type="text" name="subject" placeholder="Subject" required />
                     <textarea name="message" placeholder="Your Message" required></textarea>
                     <button type="submit" name='send' className="z-btn">Send Message</button>
                  </form>
               </div>
            </div>
         </div>
      </section>
   );
};

export default HomeContact;
