import React from 'react';

const HomeCapabilities = () => {
   return (
      <>
         <section id='about' className="capabilities__area p-relative black-bg-2 mt-200 pt-180 pb-155 fix">
            {/* <div className="capabilities__thumb p-absolute" style={{
               background: `url(assets/img/capabilities/capabilities-img.jpg)`
               , backgroundPosition: 'center', backgroundSize: 'cover'
            }}></div> */}
            <div className="capabilities__shape p-absolute wow fadeInLeft" >
               <img src="assets/img/capabilities/capabilities-shape.png" alt="shape" />
            </div>
            <div className="container">
               <div className="container__box row">
                  <div className="col-xl-5 col-lg-6">
                     <div className="capabilities__content wow fadeInUp" data-wow-delay=".4s">
                        <div className="section__title section__title-2 mb-40">
                           <div className="capabilities__list">
                              <span className="white-color">At Dezthetics</span>
                              <h2 className="text-start fs-1 fs-md-2 fs-lg-3 fs-xl-4 fs-xxl-5 w-100 w-md-75 w-lg-50 white-color"> Exceptional Video Editing That Speaks Volumes.</h2>
                              <p>We blend creativity with expertise to deliver exceptional video editing services that speak volumes.
                                 Our team, comprising some of the finest editors who have collaborated with top global creators and leading media agencies in Pakistan,
                                 is dedicated to transforming your vision into reality. We believe in the power of attention to detail, ensuring each frame is crafted with perfection.
                                 Our commitment to understanding your unique needs, combined with our unmatched quality at reasonable pricing, sets us apart.
                                 Scroll down to see our work in action and discover why Dezthetics is the choice of industry leaders.</p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-5 col-lg-6">
                     <div className="capabilities__thumb wow fadeInUp" data-wow-delay=".4s">
                        <img className='capabilities__thumb' src="assets/img/capabilities/animation.gif" />
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeCapabilities;