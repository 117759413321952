import React, { useState } from 'react';

const SingleBrandItem = ({ img_1, img_2 }) => {
   const [isHovered, setIsHovered] = useState(false);

   return (
      <>
         <div className="brand__item-wrapper">
            <div
               onMouseEnter={() => setIsHovered(true)}
               onMouseLeave={() => setIsHovered(false)}
               className="brand__item"
            >
               <img
                  style={{
                     width: "121px",
                     height: "80px",
                     marginRight: "40px",
                     marginLeft: "40px",
                     transition: "opacity 0.8s ease-in-out",
                  }}
                  src={`assets/img/brand/brand-${isHovered ? img_2 : img_1}.webp`}
                  alt=""
               />
            </div>
         </div>
      </>
   );
};

export default SingleBrandItem;
