import React from 'react';
import { Link } from 'react-scroll';

const CommonCtaArea = () => {
   return (
      <>
         <section className="cta__area pt-180 pb-155" style={{
            background: `url(assets/img/cta/cta-bg.jpg)`
            , backgroundPosition: 'center', backgroundSize: 'cover'
         }} >
            <div className="container p-relative">
               <div className="row">
                  <div className="col-xl-10 offset-xl-1">
                     <div className="cta__content text-center">
                        <h1 >Got a vision? Let’s make some magic happen!</h1>
                        <div className="cta__btn">
                           <Link smooth={true} duration={500} offset={0} to="contact" className="z-btn z-btn-white mb-30">Take a quotation now!</Link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default CommonCtaArea;