import React from 'react';
import ModalVideo from 'react-modal-video';

const VideoPopup = ({ videoId,isOpen,setIsVideoOpen }) => {

    // const { isVideoOpen, setIsVideoOpen } = useGlobalContext();
    return (
        <>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen}
                videoId={videoId} onClose={() => setIsVideoOpen(false)} />
        </>
    );
};

export default VideoPopup;