
const featuredWorksData = [
    {
        id: 1,
        img: 'assets/img/case/case-1.webp',
        title: 'Blue Band Promo',
        subtitle: 'Product Commercial',
        category: 'Commercial',
        link: 'BCpRucEG7jc'
    },
    {
        id: 2,
        img: 'assets/img/case/case-2.webp',
        title: 'Mirinda',
        subtitle: 'Product Commercial',
        category: 'Commercial',
        link: '9TYbMdR1A78'


    },
    {
        id: 3,
        img: 'assets/img/case/case-3.webp',
        title: 'Oat Som',
        subtitle: 'Product Commercial',
        category: 'Commercial',
        link: 'jllFycJBdB0'

    },
    {
        id: 4,
        img: 'assets/img/case/case-4.webp',
        title: 'Cheeks',
        subtitle: 'DVC',
        category: 'DVC',
        link: 'IAEXTc6yxpk'

    },
    {
        id: 5,
        img: 'assets/img/case/case-19.webp',
        title: 'Real Estate',
        subtitle: 'DVC',
        category: 'DVC',
        link: '7-131MJdZtA',
    },
    {
        id: 6,
        img: 'assets/img/case/case-5.webp',
        title: 'Gummies',
        subtitle: 'DVC',
        category: 'DVC',
        link: 'LGyjVcu798o',
    },
    {
        id: 7,
        img: 'assets/img/case/case-6.webp',
        title: 'Al Jayyad Perfume',
        subtitle: 'DVC',
        category: 'DVC',
        link: 'GO0r6jbGT1E',
    },
    {
        id: 8,
        img: 'assets/img/case/case-7.webp',
        title: 'Kayseria X Dananeer',
        subtitle: 'Fashion',
        category: 'Fashion',
        link: '7WRMiWNdcWw',
    },
    {
        id: 9,
        img: 'assets/img/case/case-8.webp',
        title: 'Mix Media',
        subtitle: 'Fashion',
        category: 'Fashion',
        link: 'xAyuJJUh-og',
    },
    {
        id: 10,
        img: 'assets/img/case/case-9.webp',
        title: 'E Commerce',
        subtitle: 'Short Form Content',
        category: 'Short Form Content',
        link: 'hAYS2UuoPzQ',
    },
    {
        id: 11,
        img: 'assets/img/case/case-10.webp',
        title: 'With Intention',
        subtitle: 'Short Form Content',
        category: 'Short Form Content',
        link: 'y-g1BQ6VZss',
    },
    {
        id: 12,
        img: 'assets/img/case/case-11.webp',
        title: 'Rachid',
        subtitle: 'Short Form Content',
        category: 'Short Form Content',
        link: 'm9FK91MLHuY',
    },
    {
        id: 13,
        img: 'assets/img/case/case-15..webp',
        title: 'Lawn Life',
        subtitle: 'Short Form Content',
        category: 'Short Form Content',
        link: 'RaTff4NoWMA',
    },
    {
        id: 14,
        img: 'assets/img/case/case-20.webp',
        title: 'Peanut brain',
        subtitle: 'Long Form Content',
        category: 'Long Form Content',
        link: 'pEo6FOkve9g',
    },
    {
        id: 15,
        img: 'assets/img/case/case-13.webp',
        title: 'Jake Nicolle',
        subtitle: 'Long Form Content',
        category: 'Long Form Content',
        link: 'oJXl_sGL8uU',
    },
    {
        id: 16,
        img: 'assets/img/case/case-16.webp',
        title: 'History of Porsche',
        subtitle: 'Long Form Content',
        category: 'Long Form Content',
        link: 'VwhvbhWDEZY',
    },
    {
        id: 17,
        img: 'assets/img/case/case-17.webp',
        title: 'Edgar Miami Grand Prix',
        subtitle: 'Long Form Content',
        category: 'Long Form Content',
        link: 'yv8mnuc7LaA',
    },
    {
        id: 18,
        img: 'assets/img/case/case-18.webp',
        title: 'AI Andy',
        subtitle: 'Long Form Content',
        category: 'Long Form Content',
        link: '03F_rmmiYw0',
    },
    {
        id: 19,
        img: 'assets/img/case/case-12.webp',
        title: 'Ali Abdaal Style',
        subtitle: 'Long Form Content',
        category: 'Long Form Content',
        link: 'WD1XCZfPbk4',
    },

]

export default featuredWorksData;