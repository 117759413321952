import { useState } from 'react';
import { FaInstagram, FaPinterestP, FaPhoneAlt, FaEnvelope, FaSearch, FaLinkedinIn } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar/Sidebar';
import useGlobalContext from '../../../hooks/useGlobalContext';
import { Link } from 'react-scroll';
import { BsTwitterX } from 'react-icons/bs';
const HomeOneHeader = () => {
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const { stickyMenu } = useGlobalContext();
   return (
      <>
         <header>
            <div id='home' className="header__area p-relative header__transparent">
               <div className="header__top d-none d-md-block">
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-5 col-md-4">
                           <div className="header__social">
                              <ul>
                                 <li><a target='_blank' href="https://www.instagram.com/dez_thetics/"><i ><FaInstagram /></i> </a></li>
                                 <li><a target='_blank' href="https://x.com/VFXbyHuzi"><i ><BsTwitterX /> </i></a> </li>
                                 <li><a target='_blank' href="https://www.linkedin.com/company/dezthetics/about/?viewAsMember=true"><i ><FaLinkedinIn /></i></a></li>
                              </ul>
                           </div>
                        </div>
                        <div className="col-xl-6 col-lg-7 col-md-8">
                           <div className="header__info f-right">
                              <ul>
                                 <li>
                                    <a href="tel:+923181326463">
                                       <i > <FaPhoneAlt /> </i>
                                       Call now
                                    </a>
                                 </li>
                                 <li>
                                    <a style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }} href="mailto:admin@dezthetics.com">
                                       <i> <FaEnvelope /> </i>
                                       <p style={{ marginBottom: "0px", marginTop: "3px", color: 'white', fontSize: '14px' }}>admin@dezthetics.com</p>
                                    </a>
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div id="header__sticky" className={stickyMenu ? "sticky header__bottom" : "header__bottom"}>
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                           <div className="logo">
                              <NavLink to="/">
                                 <img style={{ width: '200px' }} src="assets/img/logo/logo-white.png" alt="logo" />
                              </NavLink>
                           </div>
                           <div className="logo-gradient">
                              <NavLink to="/">
                                 <img style={{ width: '200px' }} src="assets/img/logo/dez-logo.png" alt="logo" />
                              </NavLink>
                           </div>
                        </div>
                        <div className="col-xl-9 col-lg-9 col-md-6 col-sm-6 col-6">
                           <div className="header__bottom-right d-flex justify-content-end align-items-center">
                              <div className="main-menu menu_wrapper_one">
                                 <nav id="mobile-menu">
                                    <ul>
                                       <li><Link
                                          smooth={true}
                                          offset={30}
                                          duration={500}
                                          delay={300} to="home">Home</Link> </li>
                                       <li><Link
                                          smooth={true}
                                          offset={30}
                                          duration={500}
                                          delay={300} to="about">About us</Link></li>
                                       <li><Link
                                          smooth={true}
                                          offset={30}
                                          duration={500}
                                          delay={300} to="portfolio">Our Portfolio </Link>
                                       </li>
                                       <li><Link
                                          smooth={true}
                                          offset={30}
                                          duration={500}
                                          delay={300} to="clients">Our Clients </Link>
                                       </li>
                                    </ul>
                                 </nav>
                              </div>
                              <div className="header__btn d-none d-sm-block d-lg-none d-xl-block ml-50">
                                 <Link to="contact"
                                    smooth={true}
                                    offset={30}
                                    duration={500}
                                    delay={300}
                                    className="z-btn z-btn-white">Contact Us</Link>
                              </div>
                              <div onClick={handleShow} className="sidebar__menu d-lg-none">
                                 <div className="sidebar-toggle-btn" id="sidebar-toggle">
                                    <span className="line"></span>
                                    <span className="line"></span>
                                    <span className="line"></span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="header__search-wrapper">
                  <div className="container">
                     <div className="row">
                        <div className="col-xl-12">
                           <form action="#">
                              <input type="text" placeholder="Your Keywords" />
                              <button type="button"><i > <FaSearch /> </i></button>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="body-overlay-2"></div>
            </div>
         </header>



         <Sidebar show={show} handleClose={handleClose} />

      </>
   );
};

export default HomeOneHeader;