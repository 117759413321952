import React from 'react';
import { FaEnvelope, FaInstagram, FaPhoneAlt, FaVimeoV, FaLinkedin } from 'react-icons/fa';
import { Link } from 'react-scroll'
import { BsTwitterX } from "react-icons/bs";

const HomeThreeFooter = () => {
   return (
      <>
         <footer>
            <div className="footer__area grey-bg pt-100">
               <div className="footer__top pb-45">
                  <div className="container">
                     <div className="row">
                        <div className="col-xl-6 col-lg-3 col-md-4 col-sm-6 pr-0">
                           <div className="footer__widget mb-45 wow fadeInUp" data-wow-delay=".2s">
                              <div className="footer__widget-title mb-30">
                                 <div className="logo">
                                    <a href="#">
                                       <img style={{ width: '200px', height: '40px' }} src="assets/img/logo/dez-logo.png" alt="logo" />
                                    </a>
                                 </div>
                              </div>
                              <div className="footer__widget-content">
                                 <p>Got a vision? Let’s make some magic happen! <br />Crafting Visual Masterpieces Where Design Meets Precision</p>
                                 <div className="footer__img">
                                    <img src="assets/img/footer/footer-img-1.png" alt="" />
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-2 offset-xl-1 col-lg-2 offset-lg-1 col-md-4 col-sm-6">
                           <div className="footer__widget footer__widget-3 mb-45 wow fadeInUp" data-wow-delay=".4s">
                              <div className="footer__widget-title">
                                 <h4>Company</h4>
                              </div>
                              <div className="footer__widget-content">
                                 <div className="footer__links">
                                    <ul>
                                       <li><Link
                                          smooth={true}
                                          offset={30}
                                          duration={500}
                                          delay={300} to="about">About</Link> </li>
                                       <li><Link
                                          smooth={true}
                                          offset={30}
                                          duration={500}
                                          delay={300} to="portfolio">Team Work</Link> </li>
                                       <li><Link
                                          smooth={true}
                                          offset={30}
                                          duration={500}
                                          delay={300} to="team">Lets connect to our team</Link> </li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-3  col-lg-3 col-md-4 col-sm-6">
                           <div className="footer__widget footer__widget-3 mb-45  ml-70 wow fadeInUp" data-wow-delay=".8s">
                              <div className="footer__widget-title">
                                 <h4>Information</h4>
                              </div>
                              <div className="footer__widget-content">
                                 <div className="footer__info mb-35">
                                    <ul>
                                       <li style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-start", alignItems: 'center' }}>
                                          <div className="icon theme-color ">
                                             <i> <FaEnvelope /> </i>
                                          </div>
                                          <div style={{ marginTop: "2px" }} className="text theme-color ">
                                             <span><a  href="mailto:admin@dezthetics.com">admin@dezthetics.com</a></span>
                                          </div>
                                       </li>
                                       <li>
                                          <div className="icon theme-color">
                                             <i > <FaPhoneAlt /> </i>
                                          </div>
                                          <div  style={{ marginTop: "2px" }}  className="text theme-color">
                                             <span><a target='_blank' href="tel:+923181326463">03181326463</a></span>
                                          </div>
                                       </li>
                                    </ul>
                                 </div>
                                 <div className="footer__social theme-social">
                                    <ul>
                                       <li>
                                          <a target='_blank' href="https://www.instagram.com/dez_thetics/">
                                             <i ><FaInstagram /></i>
                                             <i ><FaInstagram /></i>
                                          </a>
                                       </li>
                                       <li>
                                          <a target='_blank' href="https://x.com/VFXbyHuzi">
                                             <i ><BsTwitterX /></i>
                                             <i ><BsTwitterX /></i>
                                          </a>
                                       </li>
                                       <li>
                                          <a target='_blank' href="https://www.linkedin.com/company/dezthetics/about/?viewAsMember=true">
                                             <i ><FaLinkedin /> </i>
                                             <i ><FaLinkedin /> </i>
                                          </a>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="footer__copyright-3">
                  <div className="container">
                     <div className="footer__copyright-inner">
                        <div className="row align-items-center">
                           <div className="col-xl-12">
                              <div className="footer__copyright-text text-center">
                                 <p>Copyright © 2024 All Rights Reserved passion by <Link to="home">dezthetics</Link></p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </footer >
      </>
   );
};

export default HomeThreeFooter;