import React from 'react';
import HomeOneHeader from './HomeOneHeader/HomeOneHeader';
import HomeHeroSlider from './HomeHeroArea/HomeHeroSlider';
import HomeCapabilities from './HomeCapabilities/HomeCapabilities';
import HomeTeams from './HomeTeams/HomeTeams';
import HomeBrands from './HomeBrands/HomeBrands';
import HomeContact from './HomeContact/HomeContact';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeThreeFaq from '../HomeThree/HomeThreeFaq/HomeThreeFaq';
import HomeThreeProjects from '../HomeThree/HomeThreeProjects/HomeThreeProjects';
import HomeThreeFooter from '../HomeThree/HomeThreeFooter/HomeThreeFooter';
import HomeTwoCaseArea from '../HomeTwo/HomeTwoCaseArea/HomeTwoCaseArea';


const Home = () => {
    return (
        <>
            <PageHelmet pageTitle="Home Page" />
            <HomeOneHeader />
            <HomeHeroSlider />
            <HomeCapabilities />
            <HomeTwoCaseArea/>
            <HomeThreeProjects/>
            <CommonCtaArea />
            <HomeBrands />
            <HomeThreeFaq />
            <HomeContact />
            <HomeThreeFooter/>
            {/* <HomeTeams/> */}
        </>
    );
};

export default Home;